import(/* webpackMode: "eager" */ "/vercel/path0/app/globals.css");
import(/* webpackMode: "eager" */ "/vercel/path0/components/cart/modal.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/gtm.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/layout/navbar/mobile-menu.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/layout/navbar/search.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/meta-pixel.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/newsletter-subscribe.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/scroll-up.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/toast-context.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/toast.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Roboto\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font\",\"weight\":\"400\"}],\"variableName\":\"font\"}")