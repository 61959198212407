import { useRouter } from "next/navigation";
import { useTransition } from "react";

import { Minus, Plus } from "lucide-react";
import clsx from "clsx";
import { removeItem, updateItemQuantity } from "@/components/cart/actions";
import LoadingDots from "@/components/loading-dots";
import type { CartItem } from "@/lib/medusa/types";
import { useToast } from "@/components/toast-context";

export default function EditItemQuantityButton({
  item,
  type,
}: {
  item: CartItem;
  type: "plus" | "minus";
}) {
  const router = useRouter();
  const { setToastState } = useToast();
  const [isPending, startTransition] = useTransition();

  return (
    <button
      aria-label={
        type === "plus" ? "Increase item quantity" : "Reduce item quantity"
      }
      onClick={() => {
        startTransition(async () => {
          const error =
            type === "minus" && item.quantity - 1 === 0
              ? await removeItem(item.id)
              : await updateItemQuantity({
                  lineId: item.id,
                  quantity:
                    type === "plus" ? item.quantity + 1 : item.quantity - 1,
                });


          if (error) {
            setToastState({ open: true, message: error, type: "error" });
          }

          router.refresh();
        });
      }}
      disabled={
        isPending ||
        (type === "plus" && item.quantity === item.variant.inventory_quantity)
      }
      className={clsx(
        "ease flex h-full min-w-[36px] max-w-[36px] flex-none items-center justify-center rounded-full px-2 transition-all duration-200 hover:border-neutral-800 hover:opacity-80",
        {
          "cursor-not-allowed": isPending,
          "ml-auto": type === "minus",
        }
      )}
    >
      {isPending ? (
        <LoadingDots className="bg-black dark:bg-white" />
      ) : type === "plus" ? (
        <Plus className="h-4 w-4 dark:text-neutral-500" />
      ) : (
        <Minus className="h-4 w-4 dark:text-neutral-500" />
      )}
    </button>
  );
}
