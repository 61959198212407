"use client";

import { usePathname } from "next/navigation";
import Script from "next/script";
import { useEffect, useState } from "react";

export const pageview = () => {
  // @ts-ignore
  window.fbq("track", "PageView");
};

// https://developers.facebook.com/docs/facebook-pixel/advanced/

const MetaPixel = () => {
  const [loaded, setLoaded] = useState(false);
  const pathname = usePathname();

  useEffect(() => {
    if (!loaded) return;

    pageview();
  }, [pathname, loaded]);

  return (
      <Script
        id="fb-pixel"
        src="/meta.js"
        strategy="afterInteractive"
        onLoad={() => setLoaded(true)}
        data-pixel-id={1581988612596629}
      />
  );
};

export default MetaPixel;
