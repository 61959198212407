"use client";
import React, { useState } from "react";
function NewsletterSubscribe() {
  const [email, setEmail] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [requestError, setRequestError] = useState("");
  const [emailError, setEmailError] = useState("");

  const handleChange = (e: any) => {
    setEmailError("");
    setEmail(e.target.value);
    return;
  };
  const handleSubmit = (e?: any) => {
    e?.preventDefault();
    const isEmailValid = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ).test(email);

    if (!isEmailValid) {
      setEmailError("Adja meg az email címet");
    }

    if (!isEmailValid) {
      return;
    }

    subscribe();
  };

  const subscribe = () => {
    setLoading(true);
    return fetch("/api/newsletter", {
      method: "POST",
      body: JSON.stringify({ email }),
      headers: { "Content-type": "application/json; charset=UTF-8" },
    })
      .then((res) => {
        res.ok
          ? (() => {
              setIsSuccess(true);
              setEmail("");
            })()
          : setRequestError("Valami nem sikerült, kérem próbálja újra.");

        setTimeout(() => {
          setIsSuccess(false);
          setLoading(false);
          setRequestError("");
          setEmailError("");
        }, 5000);

        setLoading(false);
      })
      .catch(() => {
        setRequestError("Valami nem sikerült, kérem próbálja újra.");
        setLoading(false);

        setTimeout(() => {
          setIsSuccess(false);
          setLoading(false);
          setRequestError("");
          setEmailError("");
        }, 5000);
      });
  };

  const color = !!requestError || !!emailError ? "red" : "green";

  const backgroundColor = `${color}-50`;
  const textColor = `${color}-500`;
  const borderColor = `${color}-300`;

  const statusMessage =
    !!requestError || !!emailError
      ? emailError || "Hiba történt, kérem próbálja újra."
      : "Köszönöm a feliratkozást.";

  return (
    <>
      <aside className="py-10 sm:py-16 bg-gray-50 lg:py-20 dark:bg-gray-800 border-t border-b border-gray-100 dark:border-gray-700">
        <div className="px-4 mx-auto max-w-8xl">
          <h2 className="mb-4 text-xl md:text-2xl  font-bold tracking-tight text-gray-900 md:font-extrabold lg:leading-none dark:text-white md:text-center lg:mb-7">
            Iratkozzon fel hírlevelünkre
          </h2>
          <p className="mb-4 text-base text-gray-500 md:mb-6 dark:text-gray-400 md:text-center md:text-xl lg:px-20 xl:px-56">
            Szeretne értesítést kapni, amikor új termék vagy blogbejegyzés kerül
            feltöltésre webshopunkba és blogunkba? Iratkozzon fel hírlevelünkre,
            és legyen az elsők között, akik értesülnek az újdonságokról.
          </p>

          <form
            onSubmit={(e) => handleSubmit(e)}
            className="flex max-w-xl md:mx-auto mb-4"
          >
            <div className="w-full">
              <label htmlFor="email_address" className="hidden">
                Email cím
              </label>
              <div className="relative h-full">
                <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                  <svg
                    className="w-4 h-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 16"
                  >
                    <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z"></path>
                    <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z"></path>
                  </svg>
                </div>
                <input
                  type="email"
                  id="email_address"
                  name="email_address"
                  className="outline-none block w-full px-3 py-4 pl-11 text-base text-gray-900 bg-white border border-gray-200 rounded-l-xl focus:border-blue-500 focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  aria-label="Adja meg az email címét"
                  value={email}
                  onChange={handleChange}
                  placeholder="Adja meg az email címét"
                  required
                />
              </div>
            </div>

            <div>
              <input
                type="submit"
                id="member_submit"
                className="w-full px-4 py-4 text-base font-medium text-center text-white bg-blue-700 dark:bg-blue-600 dark:border-blue-600 border border-blue-700 cursor-pointer rounded-r-xl hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-blue-600 dark:hover:border-blue-700 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                value="Küldés"
              />
            </div>

            {(isSuccess || !!requestError || !!emailError) && (
              <div className="absolute w-fit">
                <div
                  className={`flex relative top-16 items-center p-3 mb-4 text-${textColor} border-t-4 border-${borderColor} bg-${backgroundColor} dark:text-${textColor} dark:bg-gray-800 dark:border-${borderColor}`}
                  role="alert"
                >
                  <svg
                    className="flex-shrink-0 w-4 h-4"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                  </svg>
                  <div className="ms-3 text-sm font-medium">
                    {statusMessage}
                  </div>
                  <button
                    type="button"
                    className={`ms-auto -mx-1.5 -my-1.5 bg-${backgroundColor} text-${textColor} rounded-lg focus:ring-2 focus:ring-${borderColor} p-1.5 hover:bg-${borderColor} inline-flex items-center justify-center h-8 w-8 dark:bg-gray-800 dark:text-${textColor} dark:hover:bg-gray-700`}
                    aria-label="Close"
                    onClick={() => {
                      setRequestError("");
                      setIsSuccess(false);
                      setEmailError("");
                    }}
                  >
                    <span className="sr-only">Bezárás</span>
                    <svg
                      className="w-3 h-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 14"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            )}
          </form>

          {/*<p className="text-sm text-gray-500 md:text-center dark:text-gray-400 font-normal">*/}
          {/*  By subscribing, you agree with{" "}*/}
          {/*  <a*/}
          {/*    rel="nofollow noopener noreferrer"*/}
          {/*    className="text-blue-600 dark-text-blue-400 hover:underline"*/}
          {/*    href="/terms"*/}
          {/*  >*/}
          {/*    Terms of Service*/}
          {/*  </a>{" "}*/}
          {/*  and{" "}*/}
          {/*  <a*/}
          {/*    className="text-blue-600 dark-text-blue-400 hover:underline"*/}
          {/*    rel="nofollow noopener noreferrer"*/}
          {/*    href="/privacy"*/}
          {/*  >*/}
          {/*    Privacy Policy*/}
          {/*  </a>*/}
          {/*  .*/}
          {/*</p>*/}
        </div>
      </aside>
    </>
  );
}

export default NewsletterSubscribe;
