'use client';

import { useRouter, useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';

import { Search } from 'lucide-react';
import { createUrl } from '@/lib/utils';

export default function SearchComponent() {
  const router = useRouter();
  const searchParams = useSearchParams();
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    setSearchValue(searchParams?.get('q') || '');
  }, [searchParams, setSearchValue]);

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const val = e.target as HTMLFormElement;
    const search = val.q as HTMLInputElement;
    const newParams = new URLSearchParams(searchParams?.toString());

    if (search.value) {
      newParams.set('q', search.value);
    } else {
      newParams.delete('q');
    }

    router.push(createUrl('/bolt/kereses', newParams));
  }

  return (
    <form onSubmit={onSubmit} action="/bolt/kereses" className="w-max-[550px] relative w-full lg:w-80 xl:w-full">
      <input
        type="text"
        name="q"
        placeholder="Termékek keresése a boltban..."
        autoComplete="off"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        className="w-full  rounded-lg outline-none border bg-white px-4 py-2 text-sm text-black placeholder:text-neutral-500 dark:border-neutral-800 dark:bg-transparent dark:text-white dark:placeholder:text-neutral-400 focus:border-blue-500 focus:ring-2 focus:ring-blue-500"
      />
      <div className="absolute right-0 top-0 mr-3 flex h-full items-center">
        <Search className="h-4" />
      </div>
    </form>
  );
}
