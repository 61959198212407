import { X } from "lucide-react";
import LoadingDots from "@/components/loading-dots";
import { useRouter } from "next/navigation";

import clsx from "clsx";
import { removeItem } from "@/components/cart/actions";
import type { CartItem } from "@/lib/medusa/types";
import { useTransition } from "react";
import {useToast} from "@/components/toast-context";

export default function DeleteItemButton({ item }: { item: CartItem }) {
  const router = useRouter();
  const [isPending, startTransition] = useTransition();
  const { setToastState } = useToast();

  return (
    <button
      aria-label="Remove cart item"
      onClick={() => {
        startTransition(async () => {
          const error = await removeItem(item.id);

          if (error) {
            setToastState({ open: true, message: error, type: "error" });
          }

          router.refresh();
        });
      }}
      disabled={isPending}
      className={clsx(
        "ease flex h-[17px] w-[17px] items-center justify-center rounded-full bg-neutral-500 transition-all duration-200",
        {
          "cursor-not-allowed px-0": isPending,
        }
      )}
    >
      {isPending ? (
        <LoadingDots className="bg-white" />
      ) : (
        <X className="hover:text-accent-3 mx-[1px] h-4 w-4 text-white dark:text-black" />
      )}
    </button>
  );
}
