"use client";
import { createContext, useContext, useState } from "react";

export type ToastState =
  | { open: true; message: string; type: "error" | "success" | "info"; }
  | { open: false; message?: string; type?: "error" | "success" | "info"; };

export const ToastContext = createContext<{
  toastState: ToastState;
  setToastState: ({ open, message, type }: ToastState) => void;
}>({
  toastState: {
    open: false,
    message: "",
    type: undefined,
  },
  setToastState: (value: ToastState) => {},
});

export const ToastProvider = ({ children }: any) => {
  const [toastState, setState] = useState<ToastState>({
    open: false,
    message: "",
    type: undefined,
  });
  const [message, setMessage] = useState("");
  const [type, setType] = useState("");

  const setToastState = ({ open, message, type }: ToastState) => {
    setState({ open, message, type } as ToastState);
  };

  return (
    <ToastContext.Provider value={{ toastState, setToastState: setToastState }}>
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  return useContext(ToastContext);
};
